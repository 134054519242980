import React, {useMemo} from "react";
import { Button, Tooltip, makeStyles, withWidth} from "@material-ui/core";
import { Link } from "react-router-dom";
import {useHistory} from "react-router-dom";
//Styles
import styles from "assets/jss/material-dashboard-react/components/CustomPricingStyle";
import classNames from "classnames";

const useStyles = makeStyles(styles);

const PlanButton = ({
  plan,
  size = 'large',
  type = 'filled',
  state,
  defaultService,
  isSubmit = true,
}) => {

  const classes = useStyles();
  let history = useHistory();

  const {
    signUpPlanUrl,
    calcumatePlans,
    upDownGradePlanUrl,
    serviceNumber,
    service,
    originPricingPlan,
    cmPlanPayRec,
    currency,
    cancelPlanUrl,
    currentPlan,

  } = state;
  const subscriptionId = state?.signedInUser?.data?.user?.stripeAccount?.subscriptionId;

  const {thePlan, thePlanLabel} = plan;
  const url = useMemo(() => {
    if (thePlan?.toLowerCase() === "contact-us") {
      return '/contact-us';
    }
    if (state?.signedInUser?.token) {
      return upDownGradePlanUrl +
        "?plan=" +
        thePlan?.toLowerCase() +
        "&" +
        service +
        "=" +
            serviceNumber;
    }
    return state.signUpPlanUrl + "?plan=" + thePlan.toLowerCase() + '&' + service + '=' + serviceNumber;        
  }, [
    upDownGradePlanUrl,
    thePlan,
    service,
    serviceNumber,
    defaultService,
  ]);


  const originalPlan = originPricingPlan;  

  const sid = useMemo(() => {
    return plan?.[`sid${currency}`] || plan.sid;
  }, [
    plan,
    currency
  ]);

  const activeIntegrationsNumber = useMemo(() => {
    return state.signedInUser.data.user.integrations.filter(elem => !elem.paused).length;
  }, [
    state.signedInUser.data.user.integrations
  ]);

  const disableSubmitButton = useMemo(() => {
    if (state.serviceNumber < activeIntegrationsNumber) {
      return true;
    }
    return false;
  }, [
    state.serviceNumber,
    state.signedInUser.data.user.integrations,
    activeIntegrationsNumber,
  ]);

  const submitButtonTitle = useMemo(() => {
    if (disableSubmitButton) {
      return `You have more than ${state.serviceNumber} calculators currently enabled. Please pause at least ${activeIntegrationsNumber - state.serviceNumber} calculators in order to proceed to downgrade your account to ${state.serviceNumber} facilities`;
    }
    return null;
  }, [
    state.serviceNumber,
    disableSubmitButton,
  ]);

  const onSubmit = (event, url) => {
    event.preventDefault();
    if (isSubmit || !url.includes('complete')) {//submit instead of scroll
      if (disableSubmitButton) return; //skip updating if serviceNumber smaller then active integrations number
      history.push(url);
    } else {
      document.querySelector('.facility-select').scrollIntoView({
        behavior: "smooth",
        alignToTop: true
      });
    }
  };

  const onCancel = (event) => {
    event.preventDefault();
    history.push(cancelPlanUrl + "?plan=" + thePlan.toLowerCase());
  }

  if (
    thePlan === currentPlan &&
    originalPlan === sid?.[(cmPlanPayRec ? 0 : 1)] &&
    !!subscriptionId &&
    serviceNumber === state.originServiceNumber &&
    defaultService === service
  ) {
    return (
      <Button
        component={Link}
        to={cancelPlanUrl + "?plan=" + thePlan.toLowerCase()}
        variant="outlined"
        color="default"
        size="small"
        fullWidth
        onClick={(event) => onCancel(event)}
        disabled={!subscriptionId}
        className={classNames(classes.submitButton, classes.cancelBtn, {
          [classes.largeButton]: size === 'large',
          [classes.smallButton]: size === 'small',
          [classes.middleButton]: size === 'middle',
          [classes.submitButtonFilled]: type === 'filled',
          [classes.submitButtonPrimary]: type === 'outlined',
        })}
      >
        Cancel subscription
      </Button>
    );
  } else {
    return (
      <Tooltip
        title={submitButtonTitle}
        //TODO find better solution to hide tooltip for free and contact us
        disableHoverListener={!submitButtonTitle || !url.includes('complete')}
        disableFocusListener={!submitButtonTitle || !url.includes('complete')}
      >
        <Button
          component={Link}
          to={url}
          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => onSubmit(event, url)}
          fullWidth
          className={classNames("submitPlanButton", classes.submitButton, {
            [classes.largeButton]: size === 'large',
            [classes.smallButton]: size === 'small',
            [classes.middleButton]: size === 'middle',
            [classes.submitButtonFilled]: type === 'filled',
            [classes.submitButtonOutlined]: type === 'outlined',
          })}
          data-cy={"button-get-plan"}
        >
          Get {thePlanLabel || thePlan}
        </Button>
      </Tooltip>
    );
  }
}

export default withWidth()(PlanButton);